import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './layout/_global-errors/page-not-found/page-not-found.component';
import { RpaNotConnectedComponent } from './layout/_global-errors/rpa-not-connected/rpa-not-connected.component';
import { LayoutComponent } from './layout/layout.component';
import { MainAuthGuard } from './shared/auth-guards/main.auth-guard';
import { MenuAuthGuard } from './shared/auth-guards/menu.auth-guard';
import { OrganizationAuthGuard } from './shared/auth-guards/organization.auth-guard';
import { PermissionAuthGuard } from './shared/auth-guards/permission.auth-guard';
import { RedirectToDefaultAuthGuard } from './shared/auth-guards/redirect-to-default.auth-guard';
import { DefaultLoadingPageComponent } from './shared/components/default-loading-page/default-loading-page.component';
import { ModuleType, PageType } from './shared/enums/page-type.enum';
import { checkRpaConnection } from './shared/auth-guards/rpa-connection.guard';

const routes: Routes = [
  {
    path: ModuleType.Auth,
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'test-page',
    loadChildren: () => import('./modules/internal/test-page/test-page.module').then(m => m.TestPageModule),
  },
  {
    path: '',
    canActivate: [MainAuthGuard],
    canDeactivate: [MainAuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: '',
        canActivate: [RedirectToDefaultAuthGuard],
        component: DefaultLoadingPageComponent,
      },
      {
        path: ModuleType.NotFound,
        component: PageNotFoundComponent,
        canActivate: [MenuAuthGuard],
      },
      {
        path: PageType.RpaNotConnected,
        component: RpaNotConnectedComponent,
        canActivate: [MenuAuthGuard],
      },
      {
        path: ModuleType.Admin,
        canActivate: [PermissionAuthGuard, MenuAuthGuard],
        loadChildren: () => import('./modules/admin-settings/admin-settings.module').then(m => m.AdminSettingsModule),
      },
      {
        path: PageType.DesignSystemOverview,
        loadChildren: () => import('./modules/internal/design-system-overview/design-system-overview.module').then(m => m.DesignSystemOverviewModule),
      },
      {
        path: ':organizationName',
        canActivate: [OrganizationAuthGuard, MenuAuthGuard],
        children: [
          {
            path: '',
            canActivate: [RedirectToDefaultAuthGuard],
            component: DefaultLoadingPageComponent,
          },
          {
            path: ModuleType.Settings,
            loadChildren: () => import('./modules/organization-settings/organization-settings.module').then(m => m.OrganizationSettingsModule),
          },
          {
            path: ModuleType.Monitoring,
            canActivate: [PermissionAuthGuard, checkRpaConnection()],
            loadChildren: () => import('./modules/monitoring/monitoring.module').then(m => m.MonitoringModule),
          },
          {
            path: ModuleType.Orchestration,
            canActivate: [checkRpaConnection()],
            loadChildren: () => import('./modules/orchestration/orchestration.module').then(m => m.OrchestrationModule),
          },
          {
            path: ModuleType.Analytics,
            canActivate: [checkRpaConnection()],
            children: [
              {
                path: PageType.DetectedIncidents,
                canActivate: [PermissionAuthGuard],
                loadChildren: () => import('./modules/analytics/assistant/assistant.module').then(m => m.AssistantModule),
              },
              {
                path: PageType.DiagnosticDashboard,
                canActivate: [PermissionAuthGuard],
                loadChildren: () => import('./modules/analytics/diagnostic-dashboard/diagnostic-dashboard.module').then(m => m.DiagnosticDashboardModule),
              },
              {
                path: PageType.RuntimeAnalysis,
                canActivate: [PermissionAuthGuard],
                loadChildren: () => import('./modules/analytics/runtime-analysis/runtime-analysis.module').then(m => m.RuntimeAnalysisModule),
              },
            ],
          },
          {
            path: ModuleType.Reporting,
            canActivate: [checkRpaConnection()],
            loadChildren: () => import('./modules/reporting/reporting.module').then(m => m.ReportingModule),
          },
        ],
      },
      { path: '**', redirectTo: ModuleType.NotFound },
    ],
  },
  { path: '**', redirectTo: ModuleType.NotFound },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollOffset: [0, 108],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
